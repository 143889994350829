


















import {Vue, Component} from 'vue-property-decorator'

@Component({})
export default class PODTracker extends Vue {
  get isSTTPODPage(): boolean {
    return ['SttPODListByCourier', 'SttPODDetail'].includes(<string>this.$route.name)
  }
}
